body{
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
}

body {
    margin: 0;
    line-height: normal;
    font-size: 14px;
    padding-right: 0 !important;
}
a, a[style]{
    text-decoration: none;
    color: #000000;
}
#header a{
    color: #2C2B2B;
    font-size: 14px;
}
#header a.active{
    color: #FF9315;
}
img{
    max-width: 100%;
}
.snipcart-cart-summary--edit.snipcart-modal__container{
    z-index: 9999;
}
.configParamLabel{
    /* margin-bottom: 5px; */
    display: block;
    width:  100%;
    font-size: 1rem;
}
.boundingBoxLabel{
 font-weight: 600;   
}
.configurationControl{
    display: inline-block;
    width: 100% !important;
}
.row_div{
    display: flex;
    justify-content: space-between;
    padding: 5px 5px 5px 0;
}
.closeOption{
    cursor: pointer;
}
.MuiStepIcon-text{
    fill: #FFFFFF !important;
}
.MuiStepIcon-root{
    width: 1.5em !important;
    height: 1.5em !important;
}
.MuiStepConnector-root.MuiStepConnector-horizontal{
    top: 1.25em !important;
    left: calc(-50% + 30px) !important;
    right: calc(50% + 30px) !important;
}

@font-face {
  font-family: "Haettenschweiler";
  src: url("https://db.onlinewebfonts.com/t/97d6f29a4bda3a872dad26cc5b2d0d7b.eot");
  src: url("https://db.onlinewebfonts.com/t/97d6f29a4bda3a872dad26cc5b2d0d7b.eot?#iefix") format("embedded-opentype"),
       url("https://db.onlinewebfonts.com/t/97d6f29a4bda3a872dad26cc5b2d0d7b.woff2") format("woff2"),
       url("https://db.onlinewebfonts.com/t/97d6f29a4bda3a872dad26cc5b2d0d7b.woff") format("woff"),
       url("https://db.onlinewebfonts.com/t/97d6f29a4bda3a872dad26cc5b2d0d7b.ttf") format("truetype"),
       url("https://db.onlinewebfonts.com/t/97d6f29a4bda3a872dad26cc5b2d0d7b.svg#Haettenschweiler") format("svg");
}
@font-face {
  font-family: "DIN Neuzeit Grotesk W01 Bold";
  src: url("https://db.onlinewebfonts.com/t/ee0b916b2e7c8fef46ce318b6f5e8d65.eot");
  src: url("https://db.onlinewebfonts.com/t/ee0b916b2e7c8fef46ce318b6f5e8d65.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/ee0b916b2e7c8fef46ce318b6f5e8d65.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/ee0b916b2e7c8fef46ce318b6f5e8d65.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/ee0b916b2e7c8fef46ce318b6f5e8d65.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/ee0b916b2e7c8fef46ce318b6f5e8d65.svg#DIN Neuzeit Grotesk W01 Bold")format("svg");
}

/*  */
.ant-upload {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    outline: 0;
  }
  .ant-upload p {
    margin: 0;
  }
  .ant-upload-btn {
    display: block;
    width: 100%;
    outline: none;
  }
  .ant-upload input[type='file'] {
    cursor: pointer;
  }
  .ant-upload.ant-upload-select {
    display: inline-block;
  }
  .ant-upload.ant-upload-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 104px;
    height: 104px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
  .ant-upload.ant-upload-select-picture-card:hover {
    border-color: #1890ff;
  }
  .ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
    border-color: #d9d9d9;
  }
  .ant-upload.ant-upload-drag {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 16px 0;
  }
  .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border-color: #096dd9;
  }
  .ant-upload.ant-upload-drag.ant-upload-disabled {
    cursor: not-allowed;
  }
  .ant-upload.ant-upload-drag .ant-upload-btn {
    display: table;
    height: 100%;
  }
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: table-cell;
    vertical-align: middle;
  }
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #40a9ff;
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 20px;
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #40a9ff;
    font-size: 48px;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
  }
  .ant-upload.ant-upload-drag p.ant-upload-hint {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .ant-upload.ant-upload-drag .anticon-plus {
    color: rgba(0, 0, 0, 0.25);
    font-size: 30px;
    transition: all 0.3s;
  }
  .ant-upload.ant-upload-drag .anticon-plus:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-upload.ant-upload-drag:hover .anticon-plus {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-upload-picture-card-wrapper {
    display: inline-block;
    width: 100%;
  }
  .ant-upload-picture-card-wrapper::before {
    display: table;
    content: '';
  }
  .ant-upload-picture-card-wrapper::after {
    display: table;
    clear: both;
    content: '';
  }
  .ant-upload-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    line-height: 1.5715;
  }
  .ant-upload-list::before {
    display: table;
    content: '';
  }
  .ant-upload-list::after {
    display: table;
    clear: both;
    content: '';
  }
  .ant-upload-list-item {
    position: relative;
    height: 22.001px;
    margin-top: 8px;
    font-size: 14px;
  }
  .ant-upload-list-item-name {
    display: inline-block;
    width: 100%;
    padding-left: 22px;
    overflow: hidden;
    line-height: 1.5715;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-upload-list-item-card-actions {
    position: absolute;
    right: 0;
  }
  .ant-upload-list-item-card-actions-btn {
    opacity: 0;
  }
  .ant-upload-list-item-card-actions-btn.ant-btn-sm {
    height: 22.001px;
    line-height: 1;
    vertical-align: top;
  }
  .ant-upload-list-item-card-actions.picture {
    top: 22px;
    line-height: 0;
  }
  .ant-upload-list-item-card-actions-btn:focus,
  .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }
  .ant-upload-list-item-card-actions .anticon {
    color: rgba(0, 0, 0, 0.45);
    transition: all 0.3s;
  }
  .ant-upload-list-item-card-actions:hover .anticon {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-upload-list-item-info {
    height: 100%;
    transition: background-color 0.3s;
  }
  .ant-upload-list-item-info > span {
    display: block;
    width: 100%;
    height: 100%;
  }
  .ant-upload-list-item-info .anticon-loading .anticon,
  .ant-upload-list-item-info .ant-upload-text-icon .anticon {
    position: absolute;
    top: 5px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: #f5f5f5;
  }
  .ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }
  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name {
    color: #ff4d4f;
  }
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
    color: #ff4d4f;
  }
  .ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }
  .ant-upload-list-item-progress {
    position: absolute;
    bottom: -12px;
    width: 100%;
    padding-left: 26px;
    font-size: 14px;
    line-height: 0;
  }
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  .ant-upload-list-picture .ant-upload-list-item:hover,
  .ant-upload-list-picture-card .ant-upload-list-item:hover {
    background: transparent;
  }
  .ant-upload-list-picture .ant-upload-list-item-error,
  .ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: #ff4d4f;
  }
  .ant-upload-list-picture .ant-upload-list-item-info,
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    padding: 0;
  }
  .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
  .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
    background: transparent;
  }
  .ant-upload-list-picture .ant-upload-list-item-uploading,
  .ant-upload-list-picture-card .ant-upload-list-item-uploading {
    border-style: dashed;
  }
  .ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    width: 48px;
    height: 48px;
    line-height: 60px;
    text-align: center;
    opacity: 0.8;
  }
  .ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
    font-size: 26px;
  }
  .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
  .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
    fill: #fff2f0;
  }
  .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
  .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
    fill: #ff4d4f;
  }
  .ant-upload-list-picture .ant-upload-list-item-icon,
  .ant-upload-list-picture-card .ant-upload-list-item-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 26px;
    transform: translate(-50%, -50%);
  }
  .ant-upload-list-picture .ant-upload-list-item-icon .anticon,
  .ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
    font-size: 26px;
  }
  .ant-upload-list-picture .ant-upload-list-item-image,
  .ant-upload-list-picture-card .ant-upload-list-item-image {
    max-width: 100%;
  }
  .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: block;
    width: 48px;
    height: 48px;
    overflow: hidden;
  }
  .ant-upload-list-picture .ant-upload-list-item-name,
  .ant-upload-list-picture-card .ant-upload-list-item-name {
    display: inline-block;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 0 0 8px;
    padding-right: 8px;
    padding-left: 48px;
    overflow: hidden;
    line-height: 44px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s;
  }
  .ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
    margin-bottom: 12px;
  }
  .ant-upload-list-picture .ant-upload-list-item-progress,
  .ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 14px;
    width: calc(100% - 24px);
    margin-top: 0;
    padding-left: 56px;
  }
  .ant-upload-list-picture-card-container {
    display: inline-block;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
    vertical-align: top;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    height: 100%;
    margin: 0;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.3s;
    content: ' ';
  }
  .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
    opacity: 1;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    z-index: 10;
    width: 16px;
    margin: 0 4px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
    color: #fff;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
  .ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
    opacity: 1;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-name {
    display: none;
    margin: 8px 0 0;
    padding: 0;
    line-height: 1.5715;
    text-align: center;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
    position: absolute;
    bottom: 10px;
    display: block;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
    background-color: #fafafa;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
    height: auto;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
  .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
    display: none;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 32px;
    width: calc(100% - 14px);
    padding-left: 0;
  }
  .ant-upload-list-text-container,
  .ant-upload-list-picture-container {
    transition: opacity 0.3s, height 0.3s;
  }
  .ant-upload-list-text-container::before,
  .ant-upload-list-picture-container::before {
    display: table;
    width: 0;
    height: 0;
    content: '';
  }
  .ant-upload-list-text-container .ant-upload-span,
  .ant-upload-list-picture-container .ant-upload-span {
    display: block;
    flex: auto;
  }
  .ant-upload-list-text .ant-upload-span,
  .ant-upload-list-picture .ant-upload-span {
    display: flex;
    align-items: center;
  }
  .ant-upload-list-text .ant-upload-span > *,
  .ant-upload-list-picture .ant-upload-span > * {
    flex: none;
  }
  .ant-upload-list-text .ant-upload-list-item-name,
  .ant-upload-list-picture .ant-upload-list-item-name {
    flex: auto;
    margin: 0;
    padding: 0 8px;
  }
  .ant-upload-list-text .ant-upload-list-item-card-actions,
  .ant-upload-list-picture .ant-upload-list-item-card-actions {
    position: static;
  }
  .ant-upload-list-text .ant-upload-text-icon .anticon {
    position: static;
  }
  .ant-upload-list .ant-upload-animate-inline-appear,
  .ant-upload-list .ant-upload-animate-inline-enter,
  .ant-upload-list .ant-upload-animate-inline-leave {
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-fill-mode: forwards;
  }
  .ant-upload-list .ant-upload-animate-inline-appear,
  .ant-upload-list .ant-upload-animate-inline-enter {
    animation-name: uploadAnimateInlineIn;
  }
  .ant-upload-list .ant-upload-animate-inline-leave {
    animation-name: uploadAnimateInlineOut;
  }
  @keyframes uploadAnimateInlineIn {
    from {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  }
  @keyframes uploadAnimateInlineOut {
    to {
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  }
  .ant-upload-rtl {
    direction: rtl;
  }
  .ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
    margin-right: auto;
    margin-left: 8px;
  }
  .ant-upload-list-rtl {
    direction: rtl;
  }
  .ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
    padding-right: 22px;
    padding-left: 14px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
    padding-right: 22px;
    padding-left: 28px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-name {
    padding-right: 22px;
    padding-left: 0;
  }
  .ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
    padding-left: 14px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-card-actions {
    right: auto;
    left: 0;
  }
  .ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
    padding-right: 0;
    padding-left: 5px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-info {
    padding: 0 4px 0 12px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
    padding-right: 0;
    padding-left: 5px;
  }
  .ant-upload-list-rtl .ant-upload-list-item-progress {
    padding-right: 26px;
    padding-left: 0;
  }
  .ant-upload-list-picture .ant-upload-list-item-info,
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    padding: 0;
  }
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    right: 8px;
    left: auto;
  }
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
    right: 50%;
    left: auto;
    transform: translate(50%, -50%);
  }
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
    margin: 0 8px 0 0;
    padding-right: 48px;
    padding-left: 8px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
    padding-right: 48px;
    padding-left: 18px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
    padding-right: 48px;
    padding-left: 36px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
    padding-right: 0;
    padding-left: 0;
  }
  .ant-upload-list-rtl .ant-upload-list-picture-card-container {
    margin: 0 0 8px 8px;
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
    right: 50%;
    left: auto;
    transform: translate(50%, -50%);
  }
  .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
    margin: 8px 0 0;
    padding: 0;
  }
/* ResizeObserver loop completed with undelivered notifications. */
iframe#webpack-dev-server-client-overlay{display:none!important}

.MuiSlider-markLabel[data-index="1"] {
  transform: translateX(-100%);
}

/* Feedback drawer styles*/
.feedbackDrawerButton {
  height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  transform-origin: bottom right;
  transform: rotate(-90deg);
  position: fixed;
  right: 0px;
  bottom: 30%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.PhoneInput{
  font-family: Noto Sans,sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  max-width: 80%;
  min-height: 40px;
  padding: 0 15px;
}
input.PhoneInputInput{
  border: none;
  font-size: inherit;
  color: currentColor;
  font-family: inherit;
}
input.PhoneInputInput:focus-visible {
  outline: none;
}
.PhoneInputInput.MuiInput-root{
  border: none;
}

input.PhoneInputInput::placeholder {
  opacity: 0.7;
}
.full-width.PhoneInput {
  max-width: 100%;
}
.ribbon{
  background-color: rgb(255, 147, 21);
  z-index: 3;
  font: 15px Sans-Serif;
  text-align: center;
  text-shadow: rgba(238, 232, 232, 0.5) 0px 1px 0px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: absolute;
  padding: 7px 0;
  left: 13px;
  top: 49px;
  width: 90px;
  color: #f3f2ef;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
}

.ribbon span:before {
  content: "";
  border-top: 10px solid rgb(255 147 21 / 66%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  bottom: -7px;
  left: -1px;
  transform: rotate(232deg);
}
#notistack-snackbar{
  max-width: 86%;
}

/* .snipcart-form__field-checkbox {
  display: none;
}

#snipcart-checkout-step-shipping .snipcart-button-link {
  display: none;
}

#snipcart-checkout-step-billing .snipcart__font--subtitle {
  visibility: hidden;
}

#snipcart-checkout-step-billing .snipcart__font--subtitle::after {
  content: "Shipping Address";
  visibility: visible;
  position: absolute;
} */
 
.camera-controls {
  display: flex;
  gap: 8px;
  padding: 5px;
  position: absolute;
  z-index: 9;
  bottom: 0;
}

.camera-controls-item {
  cursor: pointer;
  user-select: none;
  border-radius: 100%;
  background-color: white !important;
  border: 1px solid #FF9315;
  padding-top: 5px;
}
.camera-controls-item svg path {
  fill: #FF9315;
}
.camera-controls-item.active {
  background-color: #FF9315;
}
.camera-controls-item.active svg path {
  fill: #FFFFFF;
}

.camera-controls-item-free {
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid #FF9315;
  padding-top: 5px;
  opacity:  0.3;
}
.camera-controls-item-free svg path {
  fill: #FF9315;
}
.camera-controls-item-free.active {
  background-color: #FF9315;
  opacity:  1;
}
.camera-controls-item-free.active svg path {
  fill: #FFFFFF;
}

.apply-raleway-font  div, .apply-raleway-font  label, .apply-raleway-font  p, .apply-raleway-font span, .apply-raleway-font button, .apply-raleway-font .product-description h4,
.apply-raleway-font .product-description p,
.apply-raleway-font .product-description span,
.apply-raleway-font .product-description ul,
.apply-raleway-font .product-description li {
  font-family: "Raleway", serif !important;
  /* font-optical-sizing: auto; */
}
.donde-foot-color,  .donde-foot-color .MuiSlider-marked, .donde-foot-color .MuiSlider-markLabel{
  color: #322370 !important;
}
.donde-foot-color button{
  background-color: #322370;
  color: #FFFFFF !important;
}
.donde-foot-color .configurator-wrapper{
  border-color: #322370 !important;
}
.snipcart-custom-field-wrapper__input, .snipcart-cart-summary-expanded-item__custom-fields li{
  word-break: break-word;
}
@media only screen and (max-width: 460px)  {
  .camera-controls-item-free svg, .camera-controls-item svg{
    width: 18px;
    height: 20px;
  }
}